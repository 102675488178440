import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Grid, Header } from 'semantic-ui-react';
import BlogListTemplate from './BlogListTemplateOriginal';
import Layout from '../layout';

import './BlogListTemplate.less';
import SEO from '../seo';

// Wrap the tutorial content with layout.
const BlogListTemplateWithLayout = props => (
  <Layout>
    <SEO
      title="Hey Node Blog"
      description="The blog for Hey Node, a website focused on learning Node.js online through
both video and written tutorials."
      meta={[{name: 'og:type', content: 'blog' }]}
    >
    </SEO>
    <Grid container centered>
      <Grid.Row>
        <Grid.Column desktop={8} tablet={12} mobile={12}>
          {props.pageContext.currentPage > 1 ? (
            <Header as="h1">
              <Link to="/blog">Blog</Link>{' '}
              <span>
                (page {props.pageContext.currentPage} of {props.pageContext.numPages})
              </span>
            </Header>
          ) : (
            <Header as="h1">Blog</Header>
          )}
          <BlogListTemplate {...props} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Layout>
);

BlogListTemplateWithLayout.propTypes = {
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    numPages: PropTypes.number.isRequired,
  }).isRequired,
};

export default BlogListTemplateWithLayout;
